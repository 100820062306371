import { config } from "@/config";
import { getPreQueryStageParams } from "@ternary/api-lib/analytics/api/getReportData";
import { ReportDataConfig } from "../types";

type ReportConfigKey = keyof ReportDataConfig;

type ConfigKeyMapping = Record<string, ReportConfigKey>;

/**
 * Serialize complex objects like filters to strings for URL parameters
 * @param value Any value that needs to be serialized
 */
export function serializeForUrl(value: unknown): string {
  if (typeof value === "object" && value !== null) {
    return encodeURIComponent(JSON.stringify(value));
  }
  return String(value);
}

/**
 * Try to deserialize a value from URL parameter format
 * @param value The string value to deserialize
 */
export function deserializeFromUrl(value: string): unknown {
  try {
    // First try to decode the URI component if it's encoded
    const decoded = decodeURIComponent(value);

    // Check if it might be JSON (complex object)
    if (decoded.startsWith("{") || decoded.startsWith("[")) {
      return JSON.parse(decoded);
    }

    return decoded;
  } catch (e) {
    console.error("Error deserializing value:", value, e);
    return value;
  }
}

const CompressedKeyToFullKeyMapping = {
  ct: "chartType",
  cdt: "compareDurationType",
  csd: "compareStartDate",
  ced: "compareEndDate",
  ds: "dataSource",
  dim: "dimensions",
  dt: "durationType",
  sd: "startDate",
  ed: "endDate",
  ect: "excludedCreditTypes",
  eo: "excludeOther",
  f: "filters",
  fm: "formula",
  fa: "formulaAlias",
  hm: "hiddenMeasures",
  enn: "excludeNegativeNumbers",
  ic: "isCumulative",
  ifh: "isFormulaHidden",
  imh: "isMetricHidden",
  ims: "invoiceMonthStart",
  ime: "invoiceMonthEnd",
  l: "limit",
  m: "measures",
  mt: "metric",
  ma: "metricAggregate",
  mf: "metricFilters",
  nl: "nLookback",
  r: "reverse",
  sr: "sortRule",
  tg: "timeGranularity",
  xak: "xAxisKey",
} as const satisfies ConfigKeyMapping;

type CompressedKey = keyof typeof CompressedKeyToFullKeyMapping;

type FullKey = (typeof CompressedKeyToFullKeyMapping)[CompressedKey];

const DEFAULT_REPORT_CONFIG: ReportDataConfig = {
  chartType: "LINE",
  compareDurationType: null,
  compareEndDate: null,
  compareStartDate: null,
  dataSource: "BILLING",
  dimensions: [],
  durationType: "LAST_THIRTY_DAYS",
  endDate: null,
  startDate: null,
  excludedCreditTypes: [],
  excludeOther: false,
  filters: [],
  formula: null,
  formulaAlias: null,
  hiddenMeasures: [],
  excludeNegativeNumbers: false,
  isCumulative: false,
  isFormulaHidden: false,
  isMetricHidden: false,
  invoiceMonthStart: null,
  invoiceMonthEnd: null,
  limit: 0,
  measures: [],
  metric: null,
  metricAggregate: null,
  metricFilters: [],
  nLookback: 0,
  reverse: false,
  sortRule: null,
  timeGranularity: null,
  xAxisKey: null,
};

/**
 * Takes { ct: "chartType", dt: "durationType" }
 * returns { chartType: "ct", durationType: "dt" }
 */
function invertMapping<T extends Record<string, string>>(map: T) {
  // We get an array of [compressedKey, fullKey]
  const reversedEntries = Object.entries(map).map(([k, v]) => [v, k]);

  // Build an object { [fullKey]: compressedKey }
  return Object.fromEntries(reversedEntries) as {
    [V in T[keyof T]]: {
      [K in keyof T]: T[K] extends V ? K : never;
    }[keyof T];
  };
}

export const FullKeyToCompressedKeyMapping = invertMapping(
  CompressedKeyToFullKeyMapping
);

export type CompressedConfig = {
  [CK in CompressedKey]?:
    | string
    | string[]
    | number
    | boolean
    | Record<string, unknown>[];
};

/**
 * Helper function to determine if a value should be included in the compressed config
 * @param value The value to check
 * @returns Boolean indicating whether the value should be included
 */
function shouldIncludeInCompressedConfig(
  value: ReportDataConfig[ReportConfigKey]
): boolean {
  // Skip null or undefined values
  if (value === null || value === undefined) return false;

  // Handle arrays - only include if they have elements
  if (Array.isArray(value) && value.length === 0) return false;

  return true;
}

export function buildCompressedConfig(
  config: ReportDataConfig
): CompressedConfig {
  const compressed: CompressedConfig = {};

  for (const fullKey of Object.keys(config) as Array<FullKey>) {
    const compressedKey = FullKeyToCompressedKeyMapping[fullKey];
    if (!compressedKey) continue; // not in mapping

    const value = config[fullKey];

    // Optionally skip null/undefined or empty array
    if (!shouldIncludeInCompressedConfig(value)) continue;

    // Handle complex objects and arrays differently
    if (Array.isArray(value) || typeof value === "object") {
      // Filters is an array of objects, needs special serialization
      Object.assign(compressed, { [compressedKey]: serializeForUrl(value) });
    } else {
      // Handle primitive values
      Object.assign(compressed, { [compressedKey]: value });
    }
  }

  return compressed;
}

/**
 * Decompress a URL-friendly string into a report configuration
 * @param compressed The compressed string representation of the report config
 * @returns The decompressed report configuration
 */
export function decompressReportConfig(compressed: string): ReportDataConfig {
  if (!compressed) return DEFAULT_REPORT_CONFIG;

  const minimalConfig: CompressedConfig = {};
  const decodedCompressed = decodeURIComponent(compressed);

  try {
    const pairs = decodedCompressed.split("&");
    for (const pair of pairs) {
      const [key, value] = pair.split("=");
      if (!key || !value) continue;

      // Skip keys that aren't recognized compressed keys
      const validKey = key as CompressedKey;
      if (!CompressedKeyToFullKeyMapping[validKey]) {
        console.warn(`Unknown key in URL parameters: ${key}`);
        continue;
      }

      const deserializedValue = deserializeFromUrl(value) as string;

      // Try to parse numbers and booleans
      if (deserializedValue === "true") minimalConfig[validKey] = true;
      else if (deserializedValue === "false") minimalConfig[validKey] = false;
      else if (!isNaN(Number(deserializedValue)) && deserializedValue !== "")
        minimalConfig[validKey] = Number(deserializedValue);
      else minimalConfig[validKey] = deserializedValue;
    }
  } catch (error) {
    console.error("Error parsing query string config:", error);
    return DEFAULT_REPORT_CONFIG;
  }

  const fullConfig: ReportDataConfig = { ...DEFAULT_REPORT_CONFIG };

  for (const [ck, val] of Object.entries(minimalConfig)) {
    const compressedKey = ck as CompressedKey;
    const fullKey = CompressedKeyToFullKeyMapping[compressedKey];

    if (!fullKey) continue;

    Object.assign(fullConfig, { [fullKey]: val });
  }

  return fullConfig;
}

/**
 * Generate an API query suitable for the Analytics API from a ReportDataConfig.
 * @param config The report configuration
 * @returns A formatted string with a curl command for the API query
 */
export async function generateApiQuery(
  reportConfig: ReportDataConfig,
  tenantID: string = "<tenant_id>"
): Promise<string> {
  const result = await getPreQueryStageParams({
    report: reportConfig,
    dependencies: {
      tenantID,
      globalFilters: [],
      integrationMetricMap: {},
      fiscalPeriodMap: {},
      isFiscalMode: false,
    },
  });

  const analyticsApiBaseUrl =
    config.ANALYTICS_API_BASE_URL || "https://api.ternary.app/analytics";

  return `curl \
  "${analyticsApiBaseUrl}/query/load?tenant_id=${tenantID}" \
  -H "Authorization: Bearer <your-token>" \
  -H "Content-Type: application/json" \
  -d '${JSON.stringify(result.query, null, 2)}'`;
}
